import { DialogActions, DialogContainer, DialogTitle } from '@/components/Dialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';

interface ConfirmationModalProps {
  title?: string;
  message: string | ReactNode;
  confirmButton?: string;
  cancelButton?: string;
  onConfirm?: () => Promise<any> | void;
  onCancel?: () => void;
  successMessage?: string;
}

export const showConfirmationModal = (props: ConfirmationModalProps) => ModalManager.show(ConfirmationModal, props);

const ConfirmationModal = ModalManager.create<ConfirmationModalProps>(
  ({ title, message, confirmButton = 'Ok', cancelButton = 'Cancel', onConfirm, onCancel, successMessage }) => {
    const isPhoneSize = useIsPhoneSize();
    const { enqueueSnackbar } = useSnackbar();
    const modal = useModal();
    const [isConfirming, setIsConfirming] = useState(false);

    // This is to prevent the modal from closing when clicking on the backdrop
    const onClose: ModalProps['onClose'] = (event, reason) => {
      if (reason && reason === 'backdropClick') return;
      modal.remove();
    };

    return (
      <Dialog onClose={onClose} open={modal.visible} maxWidth="xs" fullWidth fullScreen={isPhoneSize}>
        <DialogContainer>
          <DialogTitle title={title} onCloseClick={modal.remove} />
          <Typography sx={{ mb: 2 }}>{message}</Typography>
          <DialogActions>
            <Button
              data-testid="confirmation-modal-cancel-button"
              onClick={() => {
                onCancel?.();
                modal.remove();
              }}
              variant="text"
            >
              {cancelButton}
            </Button>
            <LoadingButton
              data-testid="confirmation-modal-confirm-button"
              loading={isConfirming}
              onClick={async () => {
                setIsConfirming(true);
                const res = await onConfirm?.();
                setIsConfirming(false);
                if (!res?.error && successMessage) {
                  enqueueSnackbar(successMessage, { variant: 'success' });
                }

                modal.remove();
              }}
            >
              {confirmButton}
            </LoadingButton>
          </DialogActions>
        </DialogContainer>
      </Dialog>
    );
  }
);
