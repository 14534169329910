import { Alert, AlertTitle, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ErrorScreen = () => (
  <StyledBox>
    <Alert severity="error">
      <AlertTitle>Something went wrong :(</AlertTitle>
    </Alert>
  </StyledBox>
);
