import { useEffect } from 'react';
import { UseOnWindowFocusBlur } from './types';

export const useOnWindowFocusBlur = ({ onWindowFocus, onWindowBlur }: UseOnWindowFocusBlur) => {
  useEffect(() => {
    if (!onWindowFocus) {
      return;
    }
    window.addEventListener('focus', onWindowFocus);
    return () => {
      window.removeEventListener('focus', onWindowFocus);
    };
  }, [onWindowFocus]);

  useEffect(() => {
    if (!onWindowBlur) {
      return;
    }
    window.addEventListener('blur', onWindowBlur);
    return () => {
      window.removeEventListener('blur', onWindowBlur);
    };
  }, [onWindowBlur]);
};
