import Chip, { ChipProps } from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { ChipStackProps } from './types';
import { useMemo } from 'react';

const MoreChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
}));
MoreChip.defaultProps = {
  variant: 'outlined',
};

export const ChipStack = ({ labels, maxLength, color, stackProps }: ChipStackProps) => {
  const needsCollapseChip = labels.length > maxLength;
  const labelNames = [...labels];
  labelNames.length = maxLength;

  const [chipProps, moreChipProps] = useMemo<[ChipProps, ChipProps]>(() => {
    let chipProps: ChipProps = {};
    let moreChipProps: ChipProps = {};
    switch (color) {
      case 'green':
        chipProps = { variant: 'outlined', color: 'customGreen' };
        moreChipProps = { color: 'customGreen' };
        break;
      case 'purple':
        chipProps = { variant: 'outlined', color: 'customPurple' };
        moreChipProps = { color: 'customPurple' };
        // moreChipProps
        break;
      case 'grey':
        chipProps = { color: 'customGrey' };
        moreChipProps = { color: 'customGrey' };
        // moreChipProps
        break;
    }
    return [chipProps, moreChipProps];
  }, [color]);

  return (
    <Stack direction="row" spacing={1} {...stackProps}>
      {labelNames.map((label) => (
        <Chip color="primary" {...chipProps} key={label} label={label} />
      ))}
      {needsCollapseChip && <MoreChip {...moreChipProps} label={`+ ${labels.length - labelNames.length}`} />}
    </Stack>
  );
};
