import { GetFeedingPageLoadsQuery, LoadStage } from '@/web-types';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { formatNumber } from '@/components/helpers/format';
import Grid from '@mui/material/Grid';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { CallFeedAmount } from '@/components/CallFeedAmount';
import { loadStageLabel } from '@/common/dictionaries/loadStage';

const LoadAndBuildCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}));

export const LoadAndBuildChip = styled(Chip)(({ theme, ...props }) => {
  switch (props.label) {
    case loadStageLabel(LoadStage.InProgress):
      return {
        backgroundColor: theme.palette.blue[50],
        color: theme.palette.blue[600],
      };
    case loadStageLabel(LoadStage.Completed):
      return {
        backgroundColor: theme.palette.green[100],
        color: theme.palette.green[600],
      };
    default:
      return {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[600],
      };
  }
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',

  [theme.breakpoints.down('md')]: {
    strong: {
      display: 'inline-block',
      width: '46px',
    },
  },
}));

const StyledBorder = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  margin: '4px 0',
}));

export type LoadAndBuildCardProps = GetFeedingPageLoadsQuery['getLoadsByDate'][0] & Omit<CardProps, 'id'>;

export default function LoadAndBuildCard({
  loadName,
  loadedLbs,
  calledLbs,
  fedLbs,
  rationName,
  penNames = [],
  stage,
  onClick,
}: Readonly<{
  loadName?: string | null;
  loadedLbs?: number;
  calledLbs?: number;
  fedLbs?: number;
  rationName?: string;
  penNames?: string[];
  stage: LoadStage;
  onClick?: () => void;
}>) {
  const isPhoneSize = useIsPhoneSize();
  return (
    <StyledCard data-testid="loads-and-feed-card" onClick={onClick}>
      <Box padding={2}>
        <LoadAndBuildCardHeader>
          <Typography variant="h2" data-testid="loads-and-feed-card-loadIndex">
            {loadName ?? 'Load'}
          </Typography>
          <LoadAndBuildChip label={loadStageLabel(stage)} data-testid="loads-and-feed-card-stage" />
        </LoadAndBuildCardHeader>
        <Grid container spacing={{ xs: 1, md: 0 }} columns={{ xs: 12, md: 6 }}>
          <Grid item xs={6}>
            <Box data-testid="loads-and-feed-card-calledLbs">
              <strong>Called:</strong> <CallFeedAmount value={calledLbs} /> lbs
            </Box>
            <Box data-testid="loads-and-feed-card-loadedLbs">
              <strong>Loaded:</strong> {formatNumber(Math.round(loadedLbs ?? 0))} lbs
            </Box>
            <Box data-testid="loads-and-feed-card-fedLbs">
              <strong>Fed:</strong> {formatNumber(Math.round(fedLbs ?? 0))} lbs
            </Box>
          </Grid>
          {!isPhoneSize && (
            <Grid item xs={6} data-testid="loads-and-feed-card-fedLbs">
              <StyledBorder />
            </Grid>
          )}
          <Grid item xs={6}>
            {rationName && (
              <Box data-testid="loads-and-feed-card-ration">
                <strong>Ration:</strong> {rationName}
              </Box>
            )}
            <Box data-testid="loads-and-feed-card-pens">
              <strong>Pens:</strong> {penNames.join(', ')}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledCard>
  );
}
