import { kvStore } from '@/components/LocalStorage';
import { defaultKvStore } from './constants';
import { CallFeedUIState } from './types';

const STORE_KEY = 'callFeedUIState';

export const callFeedKvStore = {
  get: async (): Promise<CallFeedUIState> => ({
    ...defaultKvStore,
    ...(await kvStore.get(STORE_KEY)),
  }),
  set: async (update: Partial<CallFeedUIState>) => {
    const currentState = await callFeedKvStore.get();
    await kvStore.put(STORE_KEY, {
      ...currentState,
      ...update,
    });
  },
};
