import { useCallback, useMemo, useState } from 'react';

export const useClickTooltip = () => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => setOpen(true), []);

  return useMemo(
    () => ({
      open,
      handleClose,
      handleOpen,
    }),
    [handleClose, handleOpen, open]
  );
};
