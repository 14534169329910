import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ClickTooltipProps } from './types';
import { Typography } from '@mui/material';
import React from 'react';

const PopperProps = {
  disablePortal: true,
};

export const ClickTooltip = ({ title, open, handleClose, handleOpen, children, sx }: ClickTooltipProps) => {
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        PopperProps={{
          sx: { whiteSpace: 'initial' },
          ...PopperProps,
        }}
        onClose={handleOpen}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <Typography variant="body2" color="inherit">
            {title}
          </Typography>
        }
        sx={sx}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};
