import { LoadStage } from '@/web-types';

export const loadStageLabel = (stage: LoadStage) => {
  switch (stage) {
    case LoadStage.NotYetStarted:
      return 'Not Started';
    case LoadStage.InProgress:
      return 'In Progress';
    case LoadStage.Completed:
      return 'Completed';
    default:
      return stage;
  }
};
