import { AddDropDialog } from './AddDropDialog';
import AddLoadDialog from '@/components/BuildLoadsTab/AddLoadDialog';
import UnassignedDropsDialog from '@/components/BuildLoadsTab/UnassignedDropsDialog';
import { useDialog } from '@/components/Dialog';
import { useEditContext } from '@/components/EditContext';
import { UnsavedWarningDialog } from '@/components/UnsavedWarningDialog';
import { LoadStage, useUpdateBuildLoadsMutation } from '@/web-types';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import gql from 'graphql-tag';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BuildLoadSection from './BuildLoadSection';
import { defaultFormValue as defaultFormValues } from './constants';
import { BuildLoadsFormType, BuildLoadsTabProps, RationMapValue } from './types';
import { styled } from '@mui/material/styles';
import { positionNameSorter } from '@/common/utils/sorters';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { UnsavedFlashMessage } from '../UnsavedFlashMessage';
import { EmptyFeedingScreen } from '@/components/BuildLoadsTab/EmptyFeedingScreen';
import { DuplicateDropsEmptyView } from '@/components/CallFeedTab/DuplicateDropsEmptyView';
import { DevToolDateOverride } from '@/components/CallFeedTab';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import dayjs from 'dayjs';
import { BuildLoadsTabLoadList } from './styledComponents';
import { DateSelect } from '@/components/CallFeedTab/DateSelect';
import { enqueueSnackbar } from 'notistack';
import { CHANGES_SAVED_MESSAGE } from '@/common/messages';

const BuildLoadsLoadsCount = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

const InfoPaperContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'column',
    button: {
      width: '100%',
    },
  },
}));
const InfoPaperStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  marginRight: theme.spacing(3),
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    marginRight: 0,
  },
}));

const FeedingLoadLoadSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));
const SubHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  left: 0,
  width: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: '#fff',
  zIndex: 10,
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
    flexDirection: 'column-reverse',
  },
}));
const InfoStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
const FormControls = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const InfoPaper = ({ InfoComponent, ButtonComponent }: { InfoComponent: ReactNode; ButtonComponent: ReactNode }) => {
  const isPhoneSize = useIsPhoneSize();
  return (
    <InfoPaperContainer>
      <InfoPaperStack direction="row" spacing={isPhoneSize ? 1 : 2}>
        {InfoComponent}
      </InfoPaperStack>
      {ButtonComponent}
    </InfoPaperContainer>
  );
};

const InfoPaperValue = ({
  value,
  valueDataTestId,
  caption,
}: {
  value: string | number;
  valueDataTestId?: string;
  caption: string | number | React.ReactNode;
}) => {
  return (
    <Box display="flex" sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <BuildLoadsLoadsCount data-testid={valueDataTestId}>{value}</BuildLoadsLoadsCount>
      <Box sx={{ fontSize: 11, fontWeight: 400, lineHeight: 1.1, textAlign: 'center' }}>{caption}</Box>
    </Box>
  );
};

gql`
  mutation UpdateBuildLoads($loads: [BuildLoadInput!]!, $date: LocalDate!) {
    updateBuildLoads(loads: $loads, date: $date) {
      id
    }
  }
`;

export default function BuildLoadsTab({
  canDuplicateYesterdayDrops = false,
  loads: loadsExisting = [],
  drops: dropsExisting = [],
  onSubmit: onSubmitSuccess,
  dateOptions,
  selectedDate,
  onDateSelected,
}: BuildLoadsTabProps): JSX.Element {
  const isSuperAdmin = useIsSuperAdmin();
  const isPhoneSize = useIsPhoneSize();
  const { continueEvent, setEditing, setPreventEvent } = useEditContext();
  const [{ fetching: updating }, updateBuildLoads] = useUpdateBuildLoadsMutation();
  const methods = useForm<BuildLoadsFormType>({ defaultValues: defaultFormValues });
  const { formState, getValues, handleSubmit, reset, watch } = methods;
  const {
    open: openUnsavedWarningDialog,
    handleClose: handleCloseUnsavedWarningDialog,
    handleOpen: handleOpenUnsavedWarningDialog,
  } = useDialog();
  const {
    open: openAddDropDialog,
    handleClose: handleCloseAddDropDialog,
    handleOpen: handleOpenAddDropDialog,
  } = useDialog();
  const {
    open: openAddLoadDialog,
    handleClose: handleCloseAddLoadDialog,
    handleOpen: handleOpenAddLoadDialog,
  } = useDialog();
  const {
    open: openUnassignedDropsDialog,
    handleClose: handleCloseUnassignedDropsDialog,
    handleOpen: handleOpenUnassignedDropsDialog,
  } = useDialog();
  const [selectedLoadIndex, setSelectedLoadIndex] = useState<number | null>(null);

  const hasError = Object.keys(methods.formState.errors).length > 0;

  const isDirty = useMemo(() => {
    if (formState.dirtyFields.loads?.length) {
      if (formState.dirtyFields.loads.some((load) => load.id)) {
        return true;
      }
      if (
        formState.dirtyFields.loads
          .flatMap((load) => (Array.isArray(load.drops) ? load.drops : []))
          .some((drop) => drop.id)
      ) {
        return true;
      }
    }
    return false;
  }, [formState.dirtyFields]);

  const isTodaySelected = useMemo(() => {
    if (selectedDate && dateOptions.length) {
      return selectedDate === dateOptions[0].value;
    }
    return true;
  }, [dateOptions, selectedDate]);
  const isNoDrops = dropsExisting.length === 0;
  const isNoDropsToday = isTodaySelected && isNoDrops;
  const isNoDropsTomorrow = !isTodaySelected && isNoDrops;

  const loads = watch('loads');
  const dropIdToLoadIdMap = watch('dropIdToLoadIdMap');
  const dropsUnassignedCount = useMemo(() => {
    const loadsDropIds = new Set(loads?.flatMap((load) => load?.drops ?? []).map((drop) => drop.id));
    return dropsExisting.filter((drop) => !loadsDropIds.has(drop.id) && !dropIdToLoadIdMap[drop.id]).length;
  }, [loads, dropsExisting, dropIdToLoadIdMap]);

  const [rationsUnassignedIdSet, rationsUnassigned] = useMemo(() => {
    const rationsMap = new Map<number, RationMapValue>();
    const ids = new Set();

    dropsExisting.forEach((drop) => {
      if (dropIdToLoadIdMap[drop.id]) {
        return;
      }
      if (drop?.ration?.id) {
        const ration = rationsMap.get(drop.ration.id) ?? {
          id: drop.ration.id,
          name: drop.ration.name,
          position: drop.ration.position ?? 0,
          drops: [],
        };
        ration.drops.push(drop);
        ids.add(drop.ration.id);
        rationsMap.set(drop.ration.id, ration);
      }
    });
    let data: RationMapValue[] = [];
    rationsMap.forEach((ration, id) => {
      data.push(ration);
    });
    data = data.sort(positionNameSorter);
    return [ids, data];
  }, [dropsExisting, dropIdToLoadIdMap]);

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);
  const onOpenAddDropDialog = useCallback(
    (index: number) => () => {
      setSelectedLoadIndex(index);
      handleOpenAddDropDialog();
    },
    [handleOpenAddDropDialog]
  );
  const onSubmit = useCallback(
    async ({ loads }: BuildLoadsFormType) => {
      const dataLoad = loads.map((load) => ({
        id: load.id,
        drops: (load.drops ?? [])?.map((drop) => ({
          id: drop.id,
          rationId: drop.rationId,
          rationRevision: drop.rationRevision,
        })),
      }));

      const response = await updateBuildLoads({ loads: dataLoad, date: selectedDate });
      if (!response.error) {
        enqueueSnackbar(CHANGES_SAVED_MESSAGE, { variant: 'success' });
        await onSubmitSuccess?.();
      }
      return response;
    },
    [onSubmitSuccess, updateBuildLoads, selectedDate]
  );
  const onUnsavedSave = useCallback(async () => {
    await onSubmit(getValues());
    setEditing(false);
    handleCloseUnsavedWarningDialog();
    continueEvent();
  }, []);

  useEffect(() => {
    const loads = loadsExisting.filter((load) => load?.drops && load.drops.length > 0);
    reset((state) => ({
      ...state,
      loads,
    }));
  }, [loadsExisting, reset]);

  useEffect(() => {
    const dropIdToLoadIdMap: BuildLoadsFormType['dropIdToLoadIdMap'] = {};
    dropsExisting.forEach((drop) => {
      if (drop.loadId) {
        dropIdToLoadIdMap[drop.id] = drop.loadId;
      }
    });
    reset((state) => ({
      ...state,
      dropIdToLoadIdMap,
    }));
  }, [dropsExisting, reset]);

  useEffect(() => {
    setEditing(isDirty);
  }, [isDirty, setEditing]);

  useEffect(() => {
    setPreventEvent(() => {
      handleOpenUnsavedWarningDialog();
    });
  }, [handleOpenUnsavedWarningDialog, setPreventEvent]);

  const hasActiveLoads = loads.some((l) => l.stage != LoadStage.NotYetStarted);

  if (isTodaySelected && canDuplicateYesterdayDrops) {
    const fromDate = dayjs(dateOptions[0].value).subtract(1, 'day').format('YYYY-MM-DD');
    const toDate = dateOptions[0].value;
    return (
      <>
        <DuplicateDropsEmptyView
          isTodaySelected={isTodaySelected}
          fromDate={fromDate}
          toDate={toDate}
          onSuccess={onSubmitSuccess}
        />
        {isSuperAdmin && <DevToolDateOverride />}
      </>
    );
  }

  if (isNoDropsToday)
    return <EmptyFeedingScreen mainText="There are currently no Drops" subText="Go to Call Feed to get started" />;

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <UnsavedWarningDialog
            hasError={hasError}
            saving={updating}
            open={openUnsavedWarningDialog}
            onClose={handleCloseUnsavedWarningDialog}
            onDiscard={continueEvent}
            onContinueEditing={handleCloseUnsavedWarningDialog}
            onSave={onUnsavedSave}
          />
          <UnassignedDropsDialog
            open={openUnassignedDropsDialog}
            onClose={handleCloseUnassignedDropsDialog}
            rationsUnassigned={rationsUnassigned}
          />
          <AddDropDialog
            open={openAddDropDialog}
            onClose={handleCloseAddDropDialog}
            drops={dropsExisting}
            loadIndex={selectedLoadIndex}
          />
          <AddLoadDialog
            open={openAddLoadDialog}
            onClose={handleCloseAddLoadDialog}
            rationsUnassigned={rationsUnassigned}
            drops={dropsExisting}
          />
          <Box sx={{ position: 'relative', paddingBottom: '24px' }}>
            <SubHeader>
              {isDirty && isPhoneSize && (
                <Box sx={{ flex: 1, width: '100%', mt: '20px' }}>
                  <UnsavedFlashMessage />
                </Box>
              )}
              <InfoStack direction="row" spacing={1}>
                {!isPhoneSize && (
                  <DateSelect
                    value={selectedDate}
                    onChange={onDateSelected}
                    options={dateOptions}
                    isDisabled={isDirty || updating}
                    iconTooltip="This toggle allows you to build loads for today or tomorrow"
                  />
                )}

                {!isNoDropsTomorrow && (
                  <>
                    <InfoPaper
                      InfoComponent={
                        <InfoPaperValue
                          value={loads.length}
                          valueDataTestId="build-loads-loads-count"
                          caption="Loads"
                        />
                      }
                      ButtonComponent={
                        <Button
                          data-testid="build-loads-add-load-button"
                          size="small"
                          startIcon={isPhoneSize ? undefined : <AddIcon />}
                          onClick={handleOpenAddLoadDialog}
                          disabled={dropsUnassignedCount === 0 || updating}
                        >
                          New&nbsp;Load
                        </Button>
                      }
                    />
                    <InfoPaper
                      InfoComponent={
                        <InfoPaperValue
                          value={dropsUnassignedCount}
                          valueDataTestId="build-loads-drop-unassigned-count"
                          caption={
                            <>
                              Unassigned
                              <br />
                              Drops
                            </>
                          }
                        />
                      }
                      ButtonComponent={
                        <Button
                          data-testid="build-loads-view-unassigned-drops-button"
                          size="small"
                          onClick={handleOpenUnassignedDropsDialog}
                          disabled={dropsUnassignedCount === 0 || updating}
                        >
                          View
                        </Button>
                      }
                    />
                  </>
                )}
              </InfoStack>

              {!isNoDropsTomorrow && (
                <FormControls>
                  {isDirty && !isPhoneSize && (
                    <Box sx={{ display: { xs: 'block', sm: 'none' }, flex: 1 }}>
                      <UnsavedFlashMessage />
                    </Box>
                  )}

                  {isPhoneSize && (
                    <DateSelect
                      value={selectedDate}
                      onChange={onDateSelected}
                      options={dateOptions}
                      isDisabled={isDirty || updating}
                      iconTooltip="This toggle allows you to build loads for today or tomorrow"
                    />
                  )}

                  <Stack direction="row">
                    <Button
                      data-testid="build-loads-cancel-button"
                      type="button"
                      disabled={!isDirty || updating}
                      variant="text"
                      onClick={handleCancel}
                      sx={{ minWidth: 'auto' }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      data-testid="build-loads-save-button"
                      type="submit"
                      disabled={!isDirty || updating}
                      loading={updating}
                      sx={{ minWidth: 'auto' }}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </FormControls>
              )}
            </SubHeader>

            {isNoDropsTomorrow ? (
              <DuplicateDropsEmptyView
                fromDate={dateOptions[0].value}
                toDate={dateOptions[1].value}
                onSuccess={onSubmitSuccess}
              />
            ) : (
              <BuildLoadsTabLoadList spacing={0}>
                {loads.map((load, index) => {
                  const disableAddDrop = !rationsUnassignedIdSet.has(load?.drops?.[0]?.rationId);
                  const disabledSection = load.stage != LoadStage.NotYetStarted;

                  // User should be able to delete "not yet started" load
                  const disabledDeletingLoad = load.stage !== LoadStage.NotYetStarted

                  return (
                    <FeedingLoadLoadSection
                      key={load.id || load._uuid}
                      data-testid="feeding-load-load-section"
                      data-loadid={load.id || load._uuid}
                    >
                      <BuildLoadSection
                        loadIndex={index}
                        loadLength={loads.length}
                        load={load}
                        onOpenAddDropDialog={onOpenAddDropDialog}
                        disableDeleteDrop={updating || disabledSection}
                        disableDeleteLoad={updating || disabledSection || disabledDeletingLoad}
                        disableAddDrop={updating || disabledSection || disableAddDrop}
                        disableSorting={updating || disabledSection || hasActiveLoads}
                      />
                    </FeedingLoadLoadSection>
                  );
                })}
              </BuildLoadsTabLoadList>
            )}
          </Box>
        </form>
        {isSuperAdmin && <DevToolDateOverride />}
      </FormProvider>
    </Container>
  );
}
