import { formatNumber } from '@/components/helpers/format';
import '@/components/registerChartjs';
import { GetPenDropDetailsDialogQuery } from '@/web-types';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

const LABELS = {
  HISTORICAL_MAX: 'Historical Max',
  CALLED: 'Feed Call',
  FED_PER_HEAD: 'As Fed / HD',
  DMI_PER_HEAD: 'DMI / HD',
};

const toolTipLabelOrder = [LABELS.HISTORICAL_MAX, LABELS.CALLED, LABELS.FED_PER_HEAD, LABELS.DMI_PER_HEAD];

const GRAPH_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    tooltip: {
      itemSort: (a: any, b: any) => {
        const aIndex = toolTipLabelOrder.indexOf(a.dataset.label);
        const bIndex = toolTipLabelOrder.indexOf(b.dataset.label);
        return aIndex - bIndex;
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      ticks: {
        beginAtZero: true,
      },
      stacked: false,
    },
  },
};

export const PenDropDetailsGraph = ({ data }: { data: GetPenDropDetailsDialogQuery['getPenDropDetails'] }) => {
  const componentData = useMemo(
    () => ({
      labels: data.map((item) => dayjs(item.date).format('MM/DD')),
      datasets: [
        {
          pointRadius: 0,
          type: 'line',
          label: LABELS.HISTORICAL_MAX,
          backgroundColor: '#FBD34D',
          borderColor: '#FBD34D',
          borderWidth: 2,
          fill: false,
          data: data.map((item) => formatNumber(item.historicalMaxFedLbsPerHead)),
          borderDash: [5, 5],
          stack: 'historicalMax',
        },
        {
          type: 'line',
          label: LABELS.CALLED,
          backgroundColor: '#5842BD',
          borderColor: '#5842BD',
          borderWidth: 2,
          fill: false,
          data: data.map((item) => formatNumber(item.calledLbsPerHeadOnDate)),
          borderDash: [0, 6],
          stack: 'calledLbs',
        },
        {
          type: 'bar',
          label: LABELS.DMI_PER_HEAD,
          data: data.map((item) => formatNumber(item.dmiPerHeadOnDate)),
          backgroundColor: '#62AAF1',
          stack: 'stacked',
        },
        {
          type: 'bar',
          label: LABELS.FED_PER_HEAD,
          data: data.map((item) => formatNumber(item.fedLbsPerHeadOnDate)),
          backgroundColor: '#AFD8FB',
          stack: 'stacked',
        },
      ],
    }),
    [data]
  );

  // @ts-ignore
  return <Bar options={GRAPH_OPTIONS} data={componentData} />;
};
