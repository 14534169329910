import { useEditContext } from '@/components/EditContext';
import TabContext from '@mui/lab/TabContext';
import { useRouter } from 'next/router';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { TabNavContext } from './TabNavComponents';

export interface TabContainerProps {
  defaultTab: string;
  baseUrl: string;
  tabMap: Record<string, string>;
  children?: JSX.Element | JSX.Element[];
}

export const TabContainer = ({ defaultTab, tabMap, baseUrl, children }: TabContainerProps): JSX.Element | null => {
  const router = useRouter();
  const [tabValue, setTabValue] = useState(defaultTab);
  const [mounted, setMounted] = useState(false);
  const { checkEditingAndPreventEvent, setContinueEvent } = useEditContext();

  useEffect(() => {
    if (mounted) {
      return;
    }

    setMounted(true);

    // onMount, select tab based on the URL hash
    const routerHash = router.asPath.split('#')[1]?.split('?')[0];
    const tabValues = new Set(Object.values(tabMap));
    if (tabValues.has(routerHash)) {
      setTabValue(routerHash);
    }
  }, [mounted, router, tabMap]);

  const onTabListChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      if (checkEditingAndPreventEvent()) {
        setContinueEvent(() => {
          setTabValue(newValue);
        });
        return;
      }
      setTabValue(newValue);
      router
        .replace(`${baseUrl}#${newValue}`) // Update the URL hash
        .catch((e) => {
          // workaround for https://github.com/vercel/next.js/issues/37362
          if (!e.cancelled) {
            throw e;
          }
        });
    },
    [baseUrl, checkEditingAndPreventEvent, router, setContinueEvent]
  );

  const contextValue = useMemo(() => ({ onTabListChange }), [onTabListChange]);

  if (!mounted) {
    return null;
  }

  return (
    <TabNavContext.Provider value={contextValue}>
      <TabContext value={tabValue}>{children}</TabContext>
    </TabNavContext.Provider>
  );
};
