import { createContext, SyntheticEvent, useContext } from 'react';
import { TabList } from './styledComponents';

export const TabNavContext = createContext<{
  onTabListChange: (event: SyntheticEvent, newValue: string) => void;
}>({
  onTabListChange: () => {},
});

export const TabNavList = ({
  children,
  useMargin = true,
}: {
  children?: JSX.Element | JSX.Element[];
  useMargin?: boolean;
}): JSX.Element => {
  const { onTabListChange } = useContext(TabNavContext);
  return (
    <TabList onChange={onTabListChange} variant="fullWidth" useMargin={useMargin}>
      {children}
    </TabList>
  );
};
