import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { roundToNearestTenWithOrgSettings } from '@/components/CallFeedAmount/helpers';
import { formatNumber } from '@/components/helpers/format';

export const CallFeedAmount = ({ value }: { value?: number | null }) => {
  const [orgSettings] = useOrgSettings();

  if (!value) return 0;
  return formatNumber(roundToNearestTenWithOrgSettings(value, orgSettings));
};
