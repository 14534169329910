import { StyledTableRow } from '@/components/Feeding/styledComponents';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { FeedingLoadIngredientTableProps } from './types';
import { formatNumber } from '@/components/helpers/format';
import { TableCell } from '@mui/material';

export default function FeedingLoadIngredientTable({ ingredients }: FeedingLoadIngredientTableProps): JSX.Element {
  return (
    <TableContainer>
      <Table data-testid="feeding-load-ingredient-table">
        <TableHead>
          <StyledTableRow>
            <TableCell>Ingredient</TableCell>
            <TableCell>Called (lbs)</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {ingredients.map((row) => {
            return (
              <StyledTableRow key={row.id}>
                <TableCell scope="row">{row.name}</TableCell>
                <TableCell>{formatNumber(Math.round(row.calledLbs))}</TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
