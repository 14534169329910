import { useEffect, useMemo, useRef } from 'react';
import { useOnWindowFocusBlur } from './useOnWindowFocusBlur';
import dayjs from 'dayjs';
import { dateOverrideAtom } from '@/components/DevToolDateOverride';
import { useAtom } from 'jotai';

export const useOnDayChange = (onChange: () => void, msDelay = 0) => {
  const nowRef = useRef(dayjs.tz()); // current time when the component is mounted

  const [fakeDateChange] = useAtom(dateOverrideAtom);

  useEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeDateChange]);

  const onWindowFocusBlurProps = useMemo(
    () => ({
      onWindowFocus: () => {
        const now = dayjs.tz();
        if (now.isSame(nowRef.current, 'day')) {
          return;
        }
        onChange?.();
        nowRef.current = now; // update current time because onChange is called
      },
    }),
    [onChange]
  );
  useOnWindowFocusBlur(onWindowFocusBlurProps);

  useEffect(() => {
    const msNextDay = dayjs.tz().startOf('day').add(1, 'days').diff() + msDelay;
    const msDayInterval = 24 * 60 * 60 * 1000;
    let intervalId: ReturnType<typeof setInterval> | undefined;

    const nextTimeoutId = setTimeout(() => {
      onChange?.();
      intervalId = setInterval(() => {
        onChange?.();
      }, msDayInterval);
    }, msNextDay);

    return () => {
      clearTimeout(nextTimeoutId);
      clearInterval(intervalId);
    };
  }, [msDelay, onChange]);
};
