import { BuildLoadsFormType } from './types';

export const defaultFormValue: BuildLoadsFormType = {
  loads: [],
  dropIdToLoadIdMap: {},
  addDropDialog: {
    checkboxes: [],
  },
  addLoadDialog: {
    rationId: undefined,
    checkboxes: [],
  },
};
