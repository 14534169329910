import { IconButton, SxProps } from '@mui/material';
import React, { BaseSyntheticEvent, useCallback } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { ClickTooltip } from '@/components/ClickTooltip/ClickTooltip';
import { useClickTooltip } from '@/components/ClickTooltip/useClickTooltip';

export const ClickTooltipIcon = ({
  title,
  sx,
  icon = <InfoIcon sx={{ opacity: 0.5 }} />,
}: {
  title: string;
  sx?: SxProps;
  icon?: React.ReactNode;
}) => {
  const toolTipProps = useClickTooltip();
  const toggleTooltip = useCallback(
    (e: BaseSyntheticEvent) => {
      toolTipProps.open ? toolTipProps.handleClose() : toolTipProps.handleOpen();
      e.stopPropagation();
    },
    [toolTipProps]
  );

  return (
    <ClickTooltip sx={sx} {...toolTipProps} title={title}>
      <IconButton onClick={toggleTooltip}>{icon}</IconButton>
    </ClickTooltip>
  );
};
