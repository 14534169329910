export const createMap = <K, V>(elems: V[], getKey: (elem: V, index: number) => K): Map<K, V> => {
  const map = new Map<K, V>();
  elems.forEach((elem, index) => map.set(getKey(elem, index), elem));
  return map;
};

export const createMapWithValue = <K, V, N>(
  elems: V[],
  getKey: (elem: V, index: number) => K,
  getValue: (elem: V, index: number) => N
): Map<K, N> => {
  const map = new Map<K, N>();
  elems.forEach((elem, index) => map.set(getKey(elem, index), getValue(elem, index)));
  return map;
};
