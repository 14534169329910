import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ disabled, theme }) => ({
  background: disabled ? `${theme.palette.grey[100]} !important` : theme.palette.grey[200],
  borderRadius: '8px',
}));
StyledIconButton.defaultProps = {
  size: 'small',
};

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.grey[400] : theme.palette.grey[600],
}));

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.grey[400] : theme.palette.grey[600],
}));

interface ArrowButtonProps {
  fontSize?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  onClick: () => void;
}

export const ArrowUpwardButton = ({ disabled = false, fontSize, onClick, ...props }: ArrowButtonProps): JSX.Element => (
  <StyledIconButton {...props} onClick={onClick} disabled={disabled}>
    <StyledArrowUpwardIcon disabled={disabled} fontSize={fontSize} />
  </StyledIconButton>
);

export const ArrowDownwardButton = ({
  disabled = false,
  fontSize,
  onClick,
  ...props
}: ArrowButtonProps): JSX.Element => (
  <StyledIconButton {...props} onClick={onClick} disabled={disabled}>
    <StyledArrowDownwardIcon disabled={disabled} fontSize={fontSize} />
  </StyledIconButton>
);
