import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const FlashMessageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.yellow[100],
  // marginLeft: theme.spacing(3),
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
}));

export const FlashMessageInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
FlashMessageInfoIcon.defaultProps = {
  fontSize: 'small',
};

export const UnsavedFlashMessage = () => (
  <FlashMessageContainer>
    <FlashMessageInfoIcon data-testid="call-feed-tab-unsaved-changes-text" />
    You have unsaved changes.
  </FlashMessageContainer>
);
