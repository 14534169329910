import type { localdate } from '@/common/models/types';
import { Drop, Ingredient, IngredientHistory, RationIngredient } from '@prisma/client';

interface IngredientWithCurrentPercentDry extends Partial<Ingredient> {
  current?: Partial<IngredientHistory>;
}

interface RationIngredientWithIngredient extends Partial<RationIngredient> {
  ingredient?: IngredientWithCurrentPercentDry;
  ingredientHistory?: IngredientHistory[];
}

export interface DropWithRationIngredientWithIngredient extends Partial<Drop> {
  rationIngredients?: RationIngredientWithIngredient[];
}

/** Round a monetary value to 2 decimals */
export const roundUsd = (num: number): number => twoDecimals(num);

export const twoDecimals = (num: number): number => Math.round(num * 100) / 100;

export const threeDecimals = (num: number): number => Math.round(num * 1000) / 1000;

export const getPrice = (cost: number, markupPercent: number, shrinkPercent: number): number =>
  shrinkPercent !== 1 ? (cost * (1 + markupPercent)) / (1 - shrinkPercent) : 0;

export const getDmiPerHead = (totalLbs = 0, percentDry = 0, numHeads = 0) => {
  return (totalLbs * percentDry) / numHeads;
};

export const getCalledLbsFromDmiPerHead = (dmiPerHead = 0, percentDry = 0, numHeads = 0) => {
  return (dmiPerHead * numHeads) / percentDry;
};

export const getPercentDryByRationIngredients = (
  rationIngredients: RationIngredientWithIngredient[] = [],
  cache?: Map<string, number>,
  cacheId?: string,
  fedOn?: string
): number => {
  if (cacheId && cache && cache.has(cacheId)) {
    return cache.get(cacheId)!;
  }
  if (rationIngredients.length === 0) {
    return 0;
  }

  const totalPercentOfRationAsFed = rationIngredients.reduce((acc, { percentOfRationAsFed }) => {
    return acc + (percentOfRationAsFed || 0);
  }, 0);
  if (Math.round(totalPercentOfRationAsFed * 100) !== 100) {
    throw new Error('Total percent of ration as fed must equal 100%');
  }

  const percentDry = rationIngredients.reduce((acc, { ingredient, percentOfRationAsFed, ingredientHistory }) => {
    let percentDry = 0;
    // 1. use percent dry at fedOn date if available. percentDry can be different on different days
    // 2. default to use current percent dry if available
    if (fedOn && ingredientHistory?.length) {
      const history = ingredientHistory.find((history) => {
        return history.fromDate <= fedOn && (!history.toDate || history.toDate > fedOn);
      });
      percentDry = history?.percentDry || 0;
    } else if (ingredient?.current?.percentDry) {
      percentDry = ingredient.current.percentDry;
    }
    return acc + (percentOfRationAsFed || 0) * percentDry;
  }, 0);
  if (cacheId && cache) {
    cache.set(cacheId, percentDry);
  }
  return percentDry;
};

export const getPercentDryByDrops = (drops: DropWithRationIngredientWithIngredient[] = []): number => {
  const cache = new Map<string, number>();

  const dropsPercentDry = drops.map((drop) => {
    let fedOn = drop.date!;
    return getPercentDryByRationIngredients(
      drop.rationIngredients,
      cache,
      drop?.rationId && drop?.rationRevision ? `${drop.rationId}-${drop.rationRevision}` : undefined,
      fedOn
    );
  });
  const totalCalledLbs = drops.reduce((acc, drop) => acc + (drop.calledLbs ?? 0), 0);
  const dropsPercentCalled = drops.map((drop) => (drop.calledLbs ?? 0) / totalCalledLbs);

  return dropsPercentDry.reduce((acc, percentDry, index) => {
    if (!dropsPercentCalled[index]) {
      return acc;
    }
    return acc + percentDry * dropsPercentCalled[index];
  }, 0);
};

interface GetDmiPerHeadByDropArgDrop {
  numHeads: number;
  date: localdate;
  rationId?: number;
  rationRevision?: number;
  calledLbs?: number;
  fedLbs?: number;
  fedOn?: Date | null;
}

export const getDmiPerHeadByDrop = (
  drop: GetDmiPerHeadByDropArgDrop,
  rationIngredientsMap: Map<any, any[]>,
  useTotalCalledLbs = true
) => {
  if (!drop.numHeads) {
    return 0;
  }
  const rationIngredients = (rationIngredientsMap.get(drop.rationId!) ?? []).filter(
    (item) => item.rationRevision === drop.rationRevision!
  );
  const percentDry = getPercentDryByDrops([{ ...drop, rationIngredients }]);
  const totalLbs = useTotalCalledLbs ? drop.calledLbs : drop.fedLbs;
  return getDmiPerHead(totalLbs, percentDry, drop.numHeads);
};

export const getDmiPerHeadByDrops = (
  drops: GetDmiPerHeadByDropArgDrop[],
  rationIngredientsMap: any,
  useTotalCalledLbs = true
) => {
  return drops.reduce(
    (acc, drop) => acc + (getDmiPerHeadByDrop(drop, rationIngredientsMap, useTotalCalledLbs) ?? 0),
    0
  );
};

export const getMarkupPercent = (cost: number, price: number) => {
  if (cost == 0) {
    return price > 0 ? 1 : 0;
  }
  if (cost < 0) {
    return 1;
  }
  return price / cost - 1;
};
