import { ClickTooltipIcon } from '@/components/ClickTooltip/ClickTooltipIcon';
import SelectField from '@/components/SelectField';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';

const DateControl = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  marginRight: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
}));

export const DateSelect = ({
  value,
  onChange,
  isDisabled,
  options,
  iconTooltip,
}: {
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  options: { value: string; label: string }[];
  iconTooltip?: string;
}) => {
  const isPhoneSize = useIsPhoneSize();

  return (
    <DateControl direction="row" spacing={1} sx={{ alignItems: 'flex-start !important' }}>
      <SelectField
        disabled={isDisabled}
        value={value}
        data-testid="feeding-date-select"
        onChange={(e) => onChange(String(e.target.value))}
      >
        {options.map((option, i) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-testid={i === 0 ? 'feeding-date-option-today' : 'feeding-date-option-tomorrow'}
          >
            {isPhoneSize ? null : i === 0 ? 'Today ' : 'Tomorrow '}
            {option.label}
          </MenuItem>
        ))}
      </SelectField>
      {iconTooltip && <ClickTooltipIcon title={iconTooltip} />}
    </DateControl>
  );
};
