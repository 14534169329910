import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactNode } from 'react';
import { Controller, ControllerProps, Path, UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type ControlledCheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<CheckboxProps, 'name'> & {
      name: Path<TFieldValues>;
      rules?: ControllerProps['rules'];
      required?: boolean;
      label?: ReactNode;
      InputLabelProps?: Partial<FormControlLabelProps> & { 'data-testid'?: string };
      onChange?: CheckboxProps['onChange'];
    };

export default function ControlledCheckboxField<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules = {},
  InputLabelProps,
  label = null,
  onChange,
  ...componentProps
}: ControlledCheckboxFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { value } = field;
        return (
          <>
            <FormControlLabel
              {...InputLabelProps}
              sx={{ mr: 0, ...InputLabelProps?.sx }}
              control={
                <Checkbox
                  {...componentProps}
                  {...field}
                  checked={!!value}
                  onChange={(e, c) => {
                    field.onChange(e);
                    onChange?.(e, c);
                  }}
                />
              }
              label={label}
            />
            {!!error?.message && <FormHelperText>{error.message}</FormHelperText>}
          </>
        );
      }}
    />
  );
}
