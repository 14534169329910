import { bunkScoreLabel } from '@/common/dictionaries/bunkScore';
import { BunkScoreValue } from '@/web-types';

export const bunkScoreOptions = [
  BunkScoreValue.Slick,
  BunkScoreValue.Crumbs,
  BunkScoreValue.Lb_50,
  BunkScoreValue.Lb_100,
  BunkScoreValue.Lb_200,
  BunkScoreValue.Lb_300,
  BunkScoreValue.Lb_400,
  BunkScoreValue.OverLb_500,
].map((key) => ({
  label: bunkScoreLabel(key),
  value: key,
}));
