import { useGetPenDropDetailsDialogQuery } from '@/web-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import gql from 'graphql-tag';
import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { DialogContainer } from '../Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { PenDropsListProps } from '@/components/CallFeedTab/types';
import { DETAILS_QUERY_LIMIT } from '@/components/CallFeedTab/constants';
import { callFeedKvStore } from '@/components/CallFeedTab/callFeedKvStore';
import { PenDropsList } from '@/components/CallFeedTab/PenDropsList';
import { LoadingScreen } from '@/components/LoadingScreen';
import { ErrorScreen } from '@/components/ErrorScreen';
import { DetailsHeaderButtonWrap, PrevNextButtons } from '@/components/CallFeedTab/styledComponents';
import ButtonGroup from '@mui/material/ButtonGroup';
import { PenDropDetailsGraph } from '@/components/CallFeedTab/PenDropDetailsGraph';
import { PenDropDetailsTable } from '@/components/CallFeedTab/PenDropDetailsTable';
import { useWatch } from 'react-hook-form';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

gql`
  query GetPenDropDetailsDialog($penId: Int!, $limit: Int!) {
    getPenDropDetails(penId: $penId, limit: $limit) {
      date
      rationNames
      bunkScore
      numHeads
      dmiPerHeadOnDate
      fedLbsPerHeadOnDate
      fedLbsChange
      fedLbsOnDate
      calledLbsOnDate
      calledLbsPerHeadOnDate
      historicalMaxFedLbsPerHead
    }
  }
`;

interface ModalProps
  extends Pick<
    PenDropsListProps,
    'pens' | 'rations' | 'rationIngredientsMap' | 'isTodaySelected' | 'selectedDate' | 'formMethods'
  > {
  penIndex: number;
}

export const showPenDropDetailsModal = (props?: ModalProps) => ModalManager.show(PenDropDetailsModal, props);

const PenDropDetailsModal = ModalManager.create<ModalProps>(
  ({ pens, penIndex: originalPenIndex, rations, rationIngredientsMap, selectedDate, isTodaySelected, formMethods }) => {
    const [penIndex, setPenIndex] = useState(() => originalPenIndex);
    const pen = pens[penIndex];
    const { control, setValue } = formMethods;
    const modal = useModal();
    const [viewDetailGraph] = useWatch({ control, name: [`viewDetailGraph`] });

    const [{ fetching, data, error }] = useGetPenDropDetailsDialogQuery({
      variables: {
        penId: pen?.id,
        limit: DETAILS_QUERY_LIMIT,
      },
    });

    const graphData = useMemo(
      () => [...(data?.getPenDropDetails ?? [])].sort((a, b) => (a.date < b.date ? -1 : 1)),
      [data?.getPenDropDetails]
    );
    const tableData = useMemo(() => {
      const items = [...(data?.getPenDropDetails ?? [])].sort((a, b) => (a.date < b.date ? 1 : -1));
      items.length = Math.min(items.length, 10);
      return items;
    }, [data?.getPenDropDetails]);

    const onTableButtonClick = useCallback(() => {
      setValue('viewDetailGraph', false, { shouldDirty: false });

      callFeedKvStore.set({ viewDetailGraph: false });
    }, [setValue]);

    const onGraphButtonClick = useCallback(() => {
      setValue('viewDetailGraph', true, { shouldDirty: false });

      callFeedKvStore.set({ viewDetailGraph: true });
    }, [setValue]);

    return (
      <Dialog onClose={modal.remove} open={modal.visible} fullScreen>
        <DialogContainer data-testid="pen-drop-details-dialog" sx={{ height: '100%' }}>
          <Stack spacing={2} sx={{ height: '100%' }}>
            <PrevNextButtons>
              <Button
                variant="text"
                disabled={penIndex === 0}
                startIcon={<NavigateBeforeIcon fontSize="small" />}
                onClick={() => {
                  setPenIndex((prev) => prev - 1);
                }}
              >
                Previous Pen
              </Button>
              <Button
                variant="text"
                disabled={penIndex === pens.length - 1}
                endIcon={<NavigateNextIcon fontSize="small" />}
                onClick={() => {
                  setPenIndex((prev) => prev + 1);
                }}
              >
                Next Pen
              </Button>
            </PrevNextButtons>
            <Box sx={{ height: 188 }}>
              <PenDropsList
                key={`pens.${penIndex}`}
                pens={pens}
                penIndex={penIndex}
                rations={rations}
                rationIngredientsMap={rationIngredientsMap}
                inDialog
                isTodaySelected={isTodaySelected}
                selectedDate={selectedDate}
                onHideDetails={modal.remove}
                formMethods={formMethods}
              />
            </Box>
            {fetching && <LoadingScreen />}
            {!fetching && error && <ErrorScreen />}
            {!fetching && (
              <Stack sx={{ flex: 1 }}>
                <DetailsHeaderButtonWrap>
                  <ButtonGroup variant="outlined">
                    <Button color={!viewDetailGraph ? 'primary' : 'secondary'} onClick={onTableButtonClick}>
                      Table
                    </Button>
                    <Button color={viewDetailGraph ? 'primary' : 'secondary'} onClick={onGraphButtonClick}>
                      Graph
                    </Button>
                  </ButtonGroup>
                </DetailsHeaderButtonWrap>
                <Box sx={{ flex: 1 }}>
                  {viewDetailGraph ? (
                    <PenDropDetailsGraph data={graphData} />
                  ) : (
                    <PenDropDetailsTable data={tableData} />
                  )}
                </Box>
              </Stack>
            )}
          </Stack>
        </DialogContainer>
      </Dialog>
    );
  }
);
