import { StyledTableRow } from '@/components/Feeding/styledComponents';
import { LoadStage } from '@/web-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Stack, TableCell } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ArrowDownwardButton, ArrowUpwardButton } from './Icons';
import { BuildLoadsFormType, FeedingLoadDropTableProps, FeedingLoadDropTableRow } from './types';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { formatNumber } from '@/components/helpers/format';

export default function FeedingLoadDropTable({
  loadIndex,
  drops,
  stage,
  disableDeleteLoad = false,
  disableDeleteDrop = false,
}: FeedingLoadDropTableProps): JSX.Element {
  const isPhoneSize = useIsPhoneSize();
  const { control, setValue, watch } = useFormContext<BuildLoadsFormType>();
  const dropIdToLoadIdMap = watch('dropIdToLoadIdMap');
  const load = watch(`loads.${loadIndex}`);
  const { remove: removeLoad } = useFieldArray({ control, name: `loads` });
  const { remove: removeDrop, swap } = useFieldArray({ control, name: `loads.${loadIndex}.drops` });
  const disabledByStarted = stage != LoadStage.NotYetStarted;

  const onMoveDropUp = (dropIndex: number) => () => {
    swap(dropIndex, dropIndex - 1);
  };
  const onMoveDropDown = (dropIndex: number) => () => {
    swap(dropIndex, dropIndex + 1);
  };
  const handleDeleteDrop = (drop: FeedingLoadDropTableRow, dropIndex: number) => () => {
    const updatedMap = { ...dropIdToLoadIdMap };
    delete updatedMap[drop.id];
    setValue('dropIdToLoadIdMap', updatedMap);

    if (load?.drops?.length === 1) {
      removeLoad(loadIndex);
    } else {
      removeDrop(dropIndex);
    }
  };

  return (
    <TableContainer>
      <Table data-testid="feeding-load-drops-table">
        <TableHead>
          <TableRow>
            <TableCell>Pen</TableCell>
            <TableCell>Drop</TableCell>
            <TableCell>Called (lbs)</TableCell>
            <TableCell sx={{ width: '80px' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drops.map((drop, dropIndex) => {
            const disableDelete = disableDeleteDrop || (disableDeleteLoad && drops.length === 1);
            return (
              <StyledTableRow key={drop.id} data-testid="feeding-load-drops-row" data-dropid={drop.id}>
                <TableCell>{drop.name}</TableCell>
                <TableCell>Drop {drop.penDropIndex + 1}</TableCell>
                <TableCell>{formatNumber(drop.calledLbs)}</TableCell>
                <TableCell sx={{ padding: 0 }}>
                  <Stack direction="row" spacing={1}>
                    <ArrowUpwardButton
                      data-testid="feeding-load-move-drop-up-button"
                      disabled={disabledByStarted || dropIndex === 0}
                      onClick={onMoveDropUp(dropIndex)}
                    />

                    <ArrowDownwardButton
                      data-testid="feeding-load-move-drop-down-button"
                      disabled={disabledByStarted || dropIndex === drops.length - 1}
                      onClick={onMoveDropDown(dropIndex)}
                    />
                    <Box sx={{ pl: isPhoneSize ? 1 : 0 }}>
                      <IconButton
                        data-testid="build-loads-delete-drop-button"
                        size="small"
                        onClick={handleDeleteDrop(drop, dropIndex)}
                        disabled={disableDelete}
                      >
                        <DeleteIcon color={disableDelete ? 'inherit' : 'primary'} />
                      </IconButton>
                    </Box>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
