import { formatNumber } from '@/components/helpers/format';
import { emDash } from '@/components/Typography/emDash';
import { GetPenDropDetailsDialogQuery } from '@/web-types';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)({
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const PenDropDetailsTable = ({ data }: { data: GetPenDropDetailsDialogQuery['getPenDropDetails'] }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Head</StyledTableCell>
            <StyledTableCell>Ration</StyledTableCell>
            <StyledTableCell>Bunk Score</StyledTableCell>
            <StyledTableCell>Call</StyledTableCell>
            <StyledTableCell>Change</StyledTableCell>
            <StyledTableCell>As Fed</StyledTableCell>
            <StyledTableCell>AF/HD</StyledTableCell>
            <StyledTableCell>DMI/HD</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            let fedLbsChange = '+0';
            if (item.fedLbsChange) {
              fedLbsChange = formatNumber(item.fedLbsChange);
              if (item.fedLbsChange > 0) {
                fedLbsChange = `+${formatNumber(item.fedLbsChange)}`;
              }
            }
            return (
              <TableRow key={item.date}>
                <StyledTableCell>{dayjs(item.date).format('MM/DD')}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.numHeads)}</StyledTableCell>
                <StyledTableCell>{item.rationNames.join(', ')}</StyledTableCell>
                <StyledTableCell>{item.bunkScore ?? emDash}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.calledLbsOnDate)}</StyledTableCell>
                <StyledTableCell>{fedLbsChange}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.fedLbsOnDate)}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.fedLbsPerHeadOnDate)}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.dmiPerHeadOnDate)}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
