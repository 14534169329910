import { openDB } from 'idb';

const DATABASE_NAME = 'RedbookCattleDB';
const DATABASE_VERSION = 1;
const KV_NAME = 'keyValue';

// NOTE: indexDB is client side only. It should be used inside a useEffect hook
export const getDB = () =>
  openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      db.createObjectStore(KV_NAME);
    },
  });

export const kvStore = {
  get: async (key: string) => (await getDB()).get(KV_NAME, key),
  put: async (key: string, val: any) => (await getDB()).put(KV_NAME, val, key),
  delete: async (key: string) => (await getDB()).delete(KV_NAME, key),
  clear: async () => (await getDB()).clear(KV_NAME),
  keys: async () => (await getDB()).getAllKeys(KV_NAME),
};
