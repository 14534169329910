import { BunkScoreValue } from '@/web-types';

export const bunkScoreLabel = (bunkScore: BunkScoreValue) => {
  switch (bunkScore) {
    case BunkScoreValue.Crumbs:
      return 'Crumbs';
    case BunkScoreValue.Slick:
      return 'Slick';
    case BunkScoreValue.Lb_50:
      return '50';
    case BunkScoreValue.Lb_100:
      return '100';
    case BunkScoreValue.Lb_200:
      return '200';
    case BunkScoreValue.Lb_300:
      return '300';
    case BunkScoreValue.Lb_400:
      return '400';
    case BunkScoreValue.OverLb_500:
      return '500+';
    default:
      return bunkScore;
  }
};
