import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

export const StyledTable = styled(Table)(({ theme }) => ({
  tableLayout: 'fixed',
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: 0,

  [theme.breakpoints.down('md')]: {
    'tr:last-child th, tr:last-child td': {
      border: '0 none',
    },
  },
}));

export const StyledTableContainer = styled(TableContainer)({});

export const StyledTableWithGrayBackground = styled(StyledTable)(({ theme }) => ({
  th: {
    background: theme.palette.grey[50],
  },
  td: {
    background: theme.palette.grey[50],
  },
}));

export const StyledEmptyTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  padding: 0,
  height: theme.spacing(1),
}));

export const BoxTablePadding = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(2),
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    borderRadius: 0,
  },
}));

export const BoxTablePaddingGray = styled(BoxTablePadding)(({ theme }) => ({
  background: theme.palette.grey[50],
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child': {
    td: { borderBottom: 'none' },
  },
}));

export const StyledTotalTableRow = styled(StyledTableRow)(({ theme }) => ({
  borderRadius: theme.spacing(2),
}));

export const StyledTotalTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  background: theme.palette.grey[100],

  '&:first-of-type': {
    borderBottomLeftRadius: theme.spacing(2),
  },
  '&:last-child': {
    borderBottomRightRadius: theme.spacing(2),
  },
}));
