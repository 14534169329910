import ControlledNumberField from '@/components/ControlledNumberField';
import { DialogTitle } from '@/components/Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { CHANGES_SAVED_MESSAGE } from '@/common/messages';
import { useUpdateOrganizationCallFeedSettingsMutation } from '@/web-types';
import gql from 'graphql-tag';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';

gql`
  mutation UpdateOrganizationCallFeedSettings(
    $settingCallFeedDmiPerHeadIncrement: Float!
    $settingCallFeedAfPerHeadIncrement: Float!
    $settingCallFeedAfIncrement: Float!
  ) {
    updateOrganizationCallFeedSettings(
      settingCallFeedDmiPerHeadIncrement: $settingCallFeedDmiPerHeadIncrement
      settingCallFeedAfPerHeadIncrement: $settingCallFeedAfPerHeadIncrement
      settingCallFeedAfIncrement: $settingCallFeedAfIncrement
    ) {
      id
    }
  }
`;

interface CallFeedSettingsModalProps {
  onSuccess?: () => void;
}

export type FormFields = {
  settingCallFeedDmiPerHeadIncrement: number;
  settingCallFeedAfPerHeadIncrement: number;
  settingCallFeedAfIncrement: number;
};

export const showCallFeedSettingsModal = (props: CallFeedSettingsModalProps) =>
  ModalManager.show(CallFeedSettingsModal, props);

const CallFeedSettingsModal = ModalManager.create<CallFeedSettingsModalProps>(({ onSuccess }) => {
  const modal = useModal();

  const [
    { settingCallFeedDmiPerHeadIncrement, settingCallFeedAfPerHeadIncrement, settingCallFeedAfIncrement, refetch },
  ] = useOrgSettings();

  const [{ fetching: isUpdating }, updateOrganizationCallFeedSettings] =
    useUpdateOrganizationCallFeedSettingsMutation();

  const { handleSubmit, control } = useForm<FormFields>({
    defaultValues: {
      settingCallFeedDmiPerHeadIncrement,
      settingCallFeedAfPerHeadIncrement,
      settingCallFeedAfIncrement,
    },
  });

  const onSubmit = async (data: FormFields) => {
    const { error } = await updateOrganizationCallFeedSettings(data);
    if (error) return;

    enqueueSnackbar(CHANGES_SAVED_MESSAGE, { variant: 'success' });

    refetch?.();
    onSuccess?.();
    modal.remove();
  };

  return (
    <Dialog onClose={modal.remove} open={modal.visible} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle
          title="Feed Call Settings"
          containerProps={{ sx: { px: 4, pt: 4, mb: 2 } }}
          onCloseClick={() => modal.remove()}
        />
        <Box color="grey" sx={{ px: 4, fontSize: 13, mb: 1 }}>
          The following settings will adjust the increments for the (+) and (-) buttons used on the Call Feed Screens
        </Box>

        <Box sx={{ px: 4, py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} display="flex" alignItems="center">
              DMI /HD - Adjust Size (lbs)
            </Grid>
            <Grid item xs={6}>
              <ControlledNumberField
                name="settingCallFeedDmiPerHeadIncrement"
                decimalPlaces={2}
                control={control}
                rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
              />
            </Grid>

            <Grid item xs={6} display="flex" alignItems="center">
              AF /HD - Adjust Size (lbs)
            </Grid>
            <Grid item xs={6}>
              <ControlledNumberField
                name="settingCallFeedAfPerHeadIncrement"
                decimalPlaces={2}
                control={control}
                rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
              />
            </Grid>

            <Grid item xs={6} display="flex" alignItems="center">
              As Fed - Adjust Size (lbs)
            </Grid>
            <Grid item xs={6}>
              <ControlledNumberField
                name="settingCallFeedAfIncrement"
                decimalPlaces={2}
                control={control}
                rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, px: 4, pb: 4 }}>
          <Button variant="text" type="reset" onClick={() => modal.remove()}>
            Cancel
          </Button>
          <LoadingButton loading={isUpdating} type="submit">
            Save
          </LoadingButton>
        </Box>
      </form>
    </Dialog>
  );
});
