import LoadAndBuildCard, { LoadAndBuildCardProps } from '@/components/Feeding/LoadAndBuildCard';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useRouter } from 'next/router';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { FeedingMethod } from '@/web-types';

const Container = styled(Box)(({ theme }) => ({
  padding: '24px',
}));

interface LoadsAndFeedTabProps {
  loads: LoadAndBuildCardProps[];
}

export default function LoadsAndFeedTab({ loads }: LoadsAndFeedTabProps): JSX.Element {
  const router = useRouter();
  const [{ feedingMethod }] = useOrgSettings();
  const hasBatchFeeding = feedingMethod == FeedingMethod.Batch;
  return (
    <Container>
      <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
        {loads.map((load, index) => (
          <Grid item xs={4} md={3} key={load.id}>
            <LoadAndBuildCard
              loadName={load.name}
              loadedLbs={load.loadedLbs ?? 0}
              calledLbs={load.calledLbs ?? 0}
              fedLbs={load.fedLbs ?? 0}
              rationName={load.drops?.[0]?.ration?.name ?? ''}
              penNames={load.drops?.map((drop) => drop.pen?.name ?? '')}
              stage={load.stage}
              onClick={() =>
                router.push(
                  hasBatchFeeding
                    ? `/dashboard/feeding/batch-load/${load.id}`
                    : `/dashboard/feeding/loads-feeds/${load.id}`
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
