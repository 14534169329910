import { Typography } from '@mui/material';
import { EmptyScreen } from '@/components/EmptyScreen';
import Image from 'next/image';

export const EmptyFeedingScreen = ({ mainText, subText }: { mainText: string; subText: string }): JSX.Element => {
  return (
    <EmptyScreen Image={<Image src="/assets/images/empty-feeding.png" width={220} height={182} alt="Empty Screen" />}>
      <Typography variant="h1" sx={{ marginTop: '24px', marginBottom: '8px' }}>
        {mainText}
      </Typography>
      <Typography>{subText}</Typography>
    </EmptyScreen>
  );
};
