import MuiTabList from '@mui/lab/TabList';
import MuiTabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const TabList = styled(MuiTabList, { shouldForwardProp: (prop) => prop !== 'useMargin' })<{ useMargin?: boolean }>(
  ({ theme, useMargin }) => ({
    [theme.breakpoints.down('lg')]: {
      marginTop: useMargin ? `-${theme.spacing(3)}` : 0,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: useMargin ? `-${theme.spacing(2)}` : 0,
    },
  })
);

export const TabNavPanel = styled(MuiTabPanel)(({ theme }) => ({
  flexDirection: 'column',
  flexGrow: 30,
  overflowX: 'hidden',
  overflowY: 'auto',
  margin: `0 -${theme.spacing(3)}`,
  padding: 0,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    margin: `0 -${theme.spacing(2)}`,
  },
}));

export const TabNavPanelNoMargin = styled(MuiTabPanel)(({ theme }) => ({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  margin: 0,
  padding: 0,
}));

export const TabNavPanelContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const TabNavPanelTableWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  flexGrow: 20,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
}));
