import LinearProgress from '@mui/material/LinearProgress';
import { LinearProgressProps } from '@mui/material/LinearProgress/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

interface ProgressBarProps extends LinearProgressProps {
  text?: string | ReactElement;
  value: number;
}

export const ProgressBar = ({ text, value, ...props }: ProgressBarProps) => {
  return (
    <Box position="relative" display="flex" alignItems="center">
      {!!text && (
        <Typography fontSize={10} mr={1} width={40} textAlign="right">
          {text}
        </Typography>
      )}
      <LinearProgress
        variant="determinate"
        sx={{ padding: 1, borderRadius: 1.5, flex: 1 }}
        value={Math.min(value, 100)}
        {...props}
      />
    </Box>
  );
};
